.profile-a{
  text-decoration: none;
}
#banner{
    width: 100%;
}
.profile-h2 {
  clear: both;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 0 10px 30px;
}

.profile-h3 > span {
  border-bottom: 2px solid #c2c2c2;
  display: inline-block;
  padding: 0 5px 5px;
}
#user-profile .profile-h2 {
  padding-right: 15px;
}
#user-profile .profile-status {
  font-size: 0.75em;
  padding-left: 12px;
  margin-top: -10px;
  padding-bottom: 10px;
  color: #8dc859;
}
#user-profile .profile-status.offline {
  color: #fe635f;
}
#user-profile .profile-img {
  border: 1px solid #e1e1e1;
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#user-profile .profile-label {
  text-align: center;
  padding: 5px 0;
}
#user-profile .profile-label .label {
  padding: 5px 15px;
  font-size: 1em;
}
#user-profile .profile-stars {
  color: #faba03;
  padding: 7px 0;
  text-align: center;
}
#user-profile .profile-stars > i {
  margin-left: -2px;
}
#user-profile .profile-since {
  text-align: center;
  margin-top: -5px;
}
#user-profile .profile-details {
  padding: 15px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin: 15px 0;
}
#user-profile .profile-details ul {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 40px;
}
#user-profile .profile-details ul > li {
  margin: 3px 0;
  line-height: 1.5;
}
#user-profile .profile-details ul > li > i {
  padding-top: 2px;
}
#user-profile .profile-details ul > li > span {
  color: #34d1be;
}
#user-profile .profile-header {
  position: relative;
}
#user-profile .profile-header > .profile-h3 {
  margin-top: 10px;
}
#user-profile .profile-header .edit-profile {
  margin-top: -6px;
  position: absolute;
  right: 0;
  top: 0;
}
#user-profile .profile-tabs {
  margin-top: 30px;
}
#user-profile .profile-user-info {
  padding-bottom: 20px;
}
#user-profile .profile-user-info .profile-user-details {
  position: relative;
  padding: 4px 0;
}
#user-profile
  .profile-user-info
  .profile-user-details
  .profile-user-details-label {
  width: 110px;
  float: left;
  bottom: 0;
  font-weight: bold;
  left: 0;
  position: absolute;
  text-align: right;
  top: 0;
  width: 110px;
  padding-top: 4px;
}
#user-profile
  .profile-user-info
  .profile-user-details
  .profile-user-details-value {
  margin-left: 120px;
}
.nav-tabs{
  border-bottom: none;
}
.nav-tabs > li > a {
  z-index: 9;
  color: #AAA;
  font-size: 14px;
  font-weight: 400;
  padding: 14px 20px;
  margin-right: -1px;
  border-color: #e2e2e2;
  box-shadow: 0 5px 10px;
  cursor: pointer;
}
#user-profile .profile-social li {
  padding: 4px 0;
}
#user-profile .profile-social li > i {
  padding-top: 6px;
}
#userInfo{
    display: flex;
}
#userInfo1{
    flex: 80%;
}
#userInfo2{
    flex: 20%;
    box-shadow: 0 20px 40px rgb(0, 0, 0);
    margin-top: 1rem;
}
@media only screen and (max-width: 850px) {
    #userInfo{
        display: block;
    }
}
@media only screen and (max-width: 767px) {
  #user-profile
    .profile-user-info
    .profile-user-details
    .profile-user-details-label {
    float: none;
    position: relative;
    text-align: left;
  }
  #user-profile
    .profile-user-info
    .profile-user-details
    .profile-user-details-value {
    margin-left: 0;
  }
  #user-profile .profile-social {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 420px) {
  #user-profile .profile-header .edit-profile {
    display: block;
    position: relative;
    margin-bottom: 15px;
  }
  #user-profile .profile-message-btn .btn {
    display: block;
  }
}

.main-box {
  background: #ffffff;
  -webkit-box-shadow: 1px 1px 2px 0 #cccccc;
  -moz-box-shadow: 1px 1px 2px 0 #cccccc;
  -o-box-shadow: 1px 1px 2px 0 #cccccc;
  -ms-box-shadow: 1px 1px 2px 0 #cccccc;
  border-radius: 1rem;
  box-shadow: 0px 20px 40px rgb(0, 0, 0);
  margin-bottom: 16px;
  padding: 20px;
}
.main-box .profile-h2 {
  margin: 0 0 15px -20px;
  padding: 5px 0 5px 20px;
  border-left: 10px solid #c2c2c2; /*7e8c8d*/
}

.icon-box .btn {
  border: 1px solid #e1e1e1;
  margin-left: 3px;
  margin-right: 0;
}
.icon-box .btn:hover {
  background-color: #eee;
  color: #2bb6a3;
}

.profile-a {
  color: #2bb6a3;
  outline: none !important;
}
.profile-a:hover,
.profile-a:focus {
  color: #2bb6a3;
}

.card1{
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 20px 40px rgb(0, 0, 0);
}
.image-data{
  height: 10rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.background-image{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://ebsedu.org/wp-content/uploads/2020/06/AI-CAREER.jpg") center no-repeat;
  background-size: cover;
  z-index: -1;
  transition: transform 5s;
}
.card1:hover .background-image{
  transform: scale(1.5);
}
.user-details{
  width: 100%;
  height: 100%;
  background-image: var(--gradient-color);
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}
.user-details i{
  margin-right: 0.5rem;
}
.author{
  display: inline-block;
  margin-bottom: 0.5rem;
  transition: all 0.5s;
  color: white;
}

.author:hover{
  color: rgba(255, 255, 255, 0.75);
}
.profile-date{
  display: block;
}
.post-data{
  background-color: #fff;
  padding: 1rem 2rem;
  position: relative;
}
.profile-title{
  font-size: 2rem;
  line-height: 1;
  color: #262525;
  margin-bottom: 0.5rem;
}
.description{
  position: relative;
}
.profile-cta{
  text-align: right;
  font-weight: bold;
  margin-top: 1rem;
}
.profile-cta a{
  background: var(--gradient-color);
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
}
.profile-cta a::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 100%;
  height: 3px;
  background: var(--gradient-color);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.profile-cta a:hover::before{
  transform: scaleX(1);
}
.widget-users {
  list-style: none;
margin: 0;
padding: 0;
}
.widget-users li {
border-bottom: 1px solid #ebebeb;
padding: 15px 0;
height: 96px;
}
.widget-users li > .img {
float: left;
margin-top: 8px;
width: 50px;
height: 50px;
overflow: hidden;
border-radius: 50%;
}
.widget-users li > .details {
margin-left: 60px;
}
.widget-users li > .details > .name {
font-weight: 600;
}
.widget-users li > .details > .name > a {
color: #344644;
}
.widget-users li > .details > .name > a:hover {
color: #2bb6a3;
}
.widget-users li > .details > .time {
color: #2bb6a3;
font-size: 0.75em;
padding-bottom: 7px;
}
.widget-users li > .details > .time.online {
color: #8dc859;
}
.content {
  background-color: white;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0px 20px 40px rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  display: none;
}
.active-content{
  display: block;
}
#btnUser{
  display: flex;
  justify-content: end;
}
@media screen and (min-width: 850px){
  .card1{
    flex-direction: row;
    max-height: 50rem;
  }
  .image-data{
    flex-basis: 40%;
    height: unset;
  }
  .user-details{
    transform: translateX(-100%);
    transition: transform 0.5s;
  }
  .card1:hover .user-details{
    transform: translateX(0);
  }
  .author{
    transform: translateY(-3rem);
    opacity: 0;
    transition-delay: 0.3s;
  }
  .date{
    transform: translateY(3rem);
    opacity: 0;
    transition: all 0.5s 0.3s;
  }
  .card1:hover .author,
  .date{
    transform: translateY(0);
    opacity: 1;
  }
  .post-data{
    flex-basis: 60%;
  }
  .post-data::before{
    content: '';
    width: 3.5rem;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: -1.8rem;
    z-index: 10;
    transform: skewX(-5deg);
  }
}