div.comment {
    border: 1px solid rgb(240, 250, 240);
    background: rgba(112, 106, 106, 0.185);
    border-radius: 5px;
    opacity: 71%;
    padding: 0.5em;
    margin-bottom: 1.5em;
    width: 79%;

}

div.btn btn-success {
    width: 50%;
}

ui,
li {
    list-style: none;
}

ul.loop li ul {
    border-left: 1px solid white;
    /* position: relative; */
}

ul.loop li ul ::before {
    content: '';
    border-bottom: 1px solid;
    /* position: absolute; */

    top: 2em;
}

#main-content1 {
    color: #fcfcfc;



    border-color: white;
}

#main-content {

    text-align: center;
    margin-top: 25px;

}

#name {
    margin: 0 auto;
}


#post {
    margin: 5rem 9rem;
}

.card {
    width: 30rem;
}

.card-title {
    font-weight: bold;
}

.post-img {
    width: 30rem;
    height: 20rem;
}

.viewBorder {
    border: 1px solid black;
    z-index: 1;
    width: 60%;
}

.viewPostImage {
    height: 20rem;
}