.main{
  background: linear-gradient(rgba(196, 102, 0, 0.6), rgba(155, 89, 182, 0.6));
    color: #fff;
    font-family: "Montserrat";
    font-weight: 400;
    padding: 50px 0 100px;
    height: 100vh;
  }
  
  .SelectH1{
    font-family: "Montserrat";
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 1.5;
  }
  
  p{
    color: #8f8f8f;
  }

  .fix{
    padding: 5px 0;
  }
  
  /* navigation bar */
  
  .LogoImage{
    width: 80%;
  }

  .title-img{
    width: 90%;
  }

  .maincontainer{
    position: absolute;
    width: 250px;
    height: 320px;
    background: none;
    transform: translate(-50%, -50%);
  }

  .card1{
    top: 58%;
    left: 15%;
  }

  .card2{
    top: 58%;
    left: 35%;
  }
  
  .thecard{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.8s ease;
  }
  
  .thecard:hover{
      transform: rotateY(180deg);
    }
  
   .thefront{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    background: #E6DDC4;
    color: #000;
    text-align: center;
  }

  .theback{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    background: #fafafa;
    color: #333;
    text-align: center;
    transform: rotateY(180deg);
  }
  
  .thefront .SelectH1{
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
    padding: 30px;
  }
  
  .thefront .SelectP, .theback .SelectP{
    font-family: 'zilla slab', sans-serif;
    padding: 30px;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }

.SelectButton{
  position: relative;
  display: inline-flex;
  width: 120px;
  height: 55px;
  margin: 0 15px;
  perspective: 1000px;
  padding-top: 30px;
}

.SelectButton a{
  font-size: 1rem;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform .25s;
  font-family: 'Montserrat', sans-serif;
  
}

.SelectButton a:before, .SelectButton a:after{
  position: absolute;
  content: "EXPLORE";
  height: 55px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid black;
  box-sizing: border-box;
  border-radius: 5px;
}

.SelectButton a:before{
  color: #fff;
  background: #000;
  transform: rotateY(0deg) translateZ(25px);
}

.SelectButton a:after{
  color: #000;
  transform: rotateX(90deg) translateZ(25px);
}

.SelectButton a:hover{
  transform: translateZ(-25px) rotateX(-90deg);
}
  

/* responsive */

@media screen and (max-width: 1400px) {
  .maincontainer{
    width: 200px;
    height: 270px;
  }

  .card1{
    top:54%;
    left:17%
  }

  .card2{
    top: 54%;
    left: 37%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 1rem;
    padding-bottom: 0;
  }

  .thefront .SelectH1{
    font-size: 1.5rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }

}

@media screen and (max-width: 1100px) {
  .maincontainer{
    width: 170px;
    height: 240px;
  }

  .card1{
    top:54%;
    left:15%
  }

  .card2{
    top: 54%;
    left: 35%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 0.9rem;
    padding: 15px;
  }

  .thefront .SelectH1{
    font-size: 1.3rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }
}



@media screen and (max-width: 992px)
{

  .SelectH1{
    font-size: 2.8rem;
  }

  .maincontainer{
    width: 250px;
    height: 320px;
  }

  .card1{
    top:60%;
    left:35%
  }

  .card2{
    top: 60%;
    left: 65%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 1.2rem;
    padding: 30px;
  }

  .thefront .SelectH1{
    font-size: 2rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }

  .title-img{
    position: absolute;
    visibility: hidden;
  }

  .main{
    padding-bottom: 400px;
  }
} 

@media screen and (max-width: 900px){
  .card1{
    left: 32%;
  }

  .card2{
    left: 68%;
  }
}



@media screen and (max-width: 770px){
  .maincontainer{
    width: 200px;
    height: 270px;
  }

  .card1{
    left:32%
  }

  .card2{
    left: 68%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 1rem;
    padding-bottom: 0;
  }

  .thefront .SelectH1{
    font-size: 1.5rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }
}



@media screen and (max-width: 650px){
  .card1{
    left: 30%;
  }

  .card2{
    left: 70%;
  }
}
  
@media screen and (max-width: 540px) {
  .maincontainer{
    width: 170px;
    height: 240px;
  }

  .card1{
    left:30%
  }

  .card2{
    left: 70%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 0.9rem;
    padding: 15px;
  }

  .thefront .SelectH1{
    font-size: 1.3rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }

  .SelectH1{
    font-size: 2rem;
    padding-left: 40px;
  }
}

@media screen and (max-width: 460px){
  .maincontainer{
    width: 200px;
    height: 270px;
  }

  .card1{
    top: 40%;
    left:50%
  }

  .card2{
    top: 80%;
    left: 50%;
  }

  .thefront .SelectP, .theback .SelectP{
    font-size: 1rem;
    padding-bottom: 0;
  }

  .thefront .SelectH1{
    font-size: 1.5rem;
  }
  
  .SelectButton{
    padding-top: 15px;
  }

  .main{
    padding-bottom: 700px;
  }
}


  
  
